import PropTypes from "prop-types";
import '../utils/fontface.css';
import styled from "styled-components";
import Header from "../components/molecules/Header";
import Footer from "../components/molecules/Footer";
import GlobalStyles from "../components/atoms/GlobalStyles";
import {ThemeProvider} from "styled-components";
import {theme} from "../utils/styling";
import React from "react";

const Holder = styled.div`

`;

function Index({ children}) {

    return (
            <ThemeProvider theme={theme}>
                <>
                    <GlobalStyles/>
                    <Header />
                    <Holder>{children}</Holder>
                    <Footer />
                </>
            </ThemeProvider>
    );
}

Index.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Index;

import {Link} from "gatsby";
import React, { useState, useEffect }  from "react";
import styled from 'styled-components';
import Container from "../atoms/Container";
import PublicPurple from "../../../static/images/public.png";
import PublicYellow from "../../../static/images/uploads/public.png";
import { useLocation } from '@reach/router';

const Holder = styled.div`
  .visible {
    top: 0;
    transition: top 0.3s ease;
  }

  .hidden {
    top: -100px;
    transition: top 0.3s ease;
  }

  .home {
    background-image: none;
    background-color: black;
    color: white;
  }

  .projects {
    background-image: linear-gradient(#004d0e, 10%, #FFFFFF 20%);
    background-color: #FFFFFF;
    color: black;
  }

  .info {
    background-image: linear-gradient(#5900ff, 10%, white 20%);
    background-color: white;
    color: black;
  }
`;

const NavHolder = styled.div`
    position: fixed;
    width: 100%;
    padding: 1rem;
    z-index: 1;

    background-image: linear-gradient( #5900FF, 10%, white 20%);
    background-color: white;
    color: black;

    a {
      text-decoration: none;
      font-weight: 500;
    }
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-content: center;
`;

const LogoHolder = styled.div`
    img {
      width: 2rem;
    }
`;

const LinkHolder = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 1rem;
  align-content: center;
`;

function Header() {

  let location = useLocation();
  let pathname = location.pathname;
  let updatedPathname = pathname.replaceAll( "/", "")

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setPrevScrollPos(window.pageYOffset); // Initialize prevScrollPos once window is available
  }, []); // Empty dependency array ensures this effect runs only once after the initial render

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible = prevScrollPos > currentScrollPos || currentScrollPos < 100;

      setPrevScrollPos(currentScrollPos);
      setVisible(visible);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  

  return (
    <Holder>
      <NavHolder
        className={`${
          pathname === "/"
            ? "home"
            : pathname === "/projects/" || pathname === "/info/"
            ? updatedPathname
            : ""
        } ${visible ? "visible" : "hidden"}`}
      >
        <Container>
          <Wrapper>
            <Link to="/">
              <LogoHolder>
                <img
                  src={pathname === "/" ? PublicYellow : PublicPurple}
                  alt={""}
                />
              </LogoHolder>
            </Link>
            <LinkHolder>
              <Link to="/projects">Projects</Link>
              <Link to="/info">Information</Link>
            </LinkHolder>
          </Wrapper>
        </Container>
      </NavHolder>
    </Holder>
  );
};






export default Header;
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */


// import * as React from "react";
// import {
//   PrismicPreviewProvider,
//   componentResolverFromMap,
// } from "gatsby-plugin-prismic-previews";

// import linkResolver from "./src/utils/linkResolver";
// import CaseStudyTemplate from "./src/templates/case-study";

// export const wrapRootElement = ({ element }) => (
//   <PrismicPreviewProvider
//     repositoryConfigs={[
//       {
//         repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
//         linkResolver,
//         componentResolver: componentResolverFromMap({
//           'case-study': CaseStudyTemplate,
//         }),
//       },
//     ]}
//   >
//     {element}
//   </PrismicPreviewProvider>
// );


// If you remove the below links will automatically scroll to top on click. Very confusing.


import smoothscroll from 'smoothscroll-polyfill';

const transitionDelay = 500;
smoothscroll.polyfill();

export const shouldUpdateScroll = ({
                                routerProps: { location },
                                getSavedScrollPosition,
                              }) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    }), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || {
        top: 0,
        left: 0,
        behavior: 'smooth'
      })),
      transitionDelay
    )
  }
  return false
};

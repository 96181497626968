import {Link} from "gatsby";
import React from "react";
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import Arrow from "../../assets/svg/arrow-right.inline.svg";

const Holder = styled.div`

    a {
      text-decoration: none;
    }

    .hide {
      display: none;
    }
`;

const FooterHolder = styled.div`
    position: relative;
    display: block;
    bottom: 0;
    width: 100%;
    padding: 1rem;
    z-index: 1;
    background-color: #312000;
    color: white;
    text-align: center;
`;

const LinkHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

function Footer() {
  let location = useLocation();
  let pathname = location.pathname;
  let isHidden = pathname.startsWith('/case-study') || pathname.startsWith('/field-tests');

  return (
        <Holder>
          <FooterHolder className={ isHidden ? 'hide' : '' }>
              <LinkHolder>
                <Link to="/field-tests">FIELD TESTS </Link><Arrow />
              </LinkHolder>
          </FooterHolder>
        </Holder>
  );
};


export default Footer;